import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Container from "../components/Container";
import { Flex, Box } from "@rebass/grid";
import H1 from "../components/H1";
import H2 from "../components/H2";
import Lead from "../components/Lead";
import Button from "../components/Button";
import Paragraph from "../components/Paragraph";

const HeaderImage = styled.div`
  background-size: contain;
  width: 100%;
  padding-bottom: 100%;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const AboutPageTemplate = ({ title, headerImage }) => {
  return (
    <Container>
      <Helmet title={`Over ons | Appmantle`}>
        <meta
          name="description"
          content="Wij staan ervoor om ingewikkelde en prijzige technologie voor
            iedereen toegankelijk te maken."
        />
        <link rel="canonical" href="https://appmantle.com/over-appmantle/" />
      </Helmet>
      <Flex>
        <Box width={[1, 2 / 3, 1 / 2]} my={[4, 5, 6]}>
          <H1 as="h1">Wat wij doen</H1>
          <Lead>
            Wij staan ervoor om ingewikkelde en prijzige technologie voor
            iedereen toegankelijk te maken.
          </Lead>
        </Box>
      </Flex>
      <Flex flexWrap="wrap" mx={[0, 5, 6]} mb={5}>
        <Box width={[1, 1 / 3, 1 / 3]} pr={2}>
          <H2>Hoe het begon</H2>
        </Box>
        <Box width={[1, 2 / 3, 2 / 3]}>
          <Paragraph>
            De markt van de apps stijgt elk jaar. Tegelijkertijd is het voor
            kleine tot middelgrote ondernemers nog altijd lastig om een eigen
            app te laten ontwikkelen. Een app laten maken kost namelijk vaak
            veel tijd en geld.
          </Paragraph>
          <Paragraph>
            Om dit probleem op te lossen, zijn we in 2016 begonnen met het
            ontwikkelen van een prototype om op een eenvoudige en laagdrempelige
            manier apps te bouwen. Dit prototype hebben wij uitgezet bij een
            festival van de Hanzehogeschool Groningen. Duizenden mensen maakten
            gebruik van de app. Het prototype bleef probleemloos online. De
            reacties waren erg positief en de oplossing was hiermee gevalideerd.
          </Paragraph>
          <Paragraph>
            Vervolgens is het systeem uitgebouwd zoals het nu is. Een
            gebruiksvriendelijk, laagdrempelig en toegankelijk platform waarmee
            iedereen een eigen app mee moet kunnen ontwikkelen.
          </Paragraph>
          <Paragraph>
            Kun jij je vinden in onze missie om ingewikkelde en prijzige
            technologie voor iedereen toegankelijk te maken? Bekijk dan snel
            onze vacatures.
          </Paragraph>
          <Button as={Link} to="/vacatures/">
            Bekijk vacatures
          </Button>
        </Box>
      </Flex>
      <Flex flexWrap="wrap" mx={[0, 5, 6]} mb={[4, 5, 6]}>
        <Box width={[1, 1 / 3, 1 / 3]} pr={2}>
          <H2>Contact</H2>
        </Box>
        <Box width={[1, 2 / 3, 2 / 3]}>
          <Paragraph>
            Je kunt makkelijk contact met ons opnemen door een brief of een
            e-mail te sturen.
          </Paragraph>

          <Paragraph>
            Appmantle
            <br />
            Herestraat 106
            <br />
            9711 LM Groningen
            <br />
            Nederland
          </Paragraph>

          <Paragraph>
            E-mailadres:{" "}
            <a href="mailto:hallo@appmantle.com">hallo@appmantle.com</a>
          </Paragraph>

          <Paragraph>
            KvK: 69885494 <br />
            Btw: NL858052313B01
          </Paragraph>
        </Box>
      </Flex>
    </Container>
  );
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        headerImage={post.frontmatter.headerImage}
      />
    </Layout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        headerImage {
          publicURL
        }
      }
    }
  }
`;
